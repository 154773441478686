import React from 'react';
import cx from 'classnames';
import {ArticlePreview} from '@cg-squad/ui-components';

class ArticlePopularPosts extends React.Component {

  constructor (props) {
    super(props);
    this.className = cx(
      'hidden w-[305px] lg:flex flex-col justify-between p-6 pb-2 bg-gray-220',
      props.className
    );
  }

  render () {
    return <div className={'popular-posts article-popular relative ml-3 min-w-[305px]'}>
      <div className={this.className} data-datocms-noindex>
        {/*<div className={"mt-1 text-denim font-bold text-xl"}>
                    What to read next...
                </div>*/}
        {
          (this.props.articles || []).slice(0, 3)
            .map((article, index) => {
              return <ArticlePreview
                key={`top-right-${index}`} data={article}
                className="flex w-full mb-4"
                imageClass="mb-0 mr-2 float-left clear-both w-[70px] h-[70px]"
                gatsbyImageClass="w-[70px] h-[70px]"
                headingClass="font-outfit font-normal leading-snug text-base mb-0 line-clamp-3"
                noImage={true}
                noFlag={true}
                noBody={true}
                noAuthor={true}
                wrappedImg={false}
                dateAsFlag={true}
                showNumber={true}
                number={index + 1}
                authorAndReadingTime={true}
                noReadMore={true}
                imageLoading={this.props.imageLoading}
                titleAuthorDisplayClass={'flex-col-reverse'}
                publishedAtFormat={'mmmm dd, yyyy'}/>;
            })
        }
      </div>
      {/*{this.props.rightSideAffiliate && <div className={"text-center my-4"} dangerouslySetInnerHTML={{__html: this.props.rightSideAffiliate}}/>}*/}
      {this.props.rightSideAffiliate
        ?
        <div className={'text-center my-4'}>{this.props.rightSideAffiliate}</div>
        :
        <div className="ad-container my-2 min-w-[305px]">
          <div className="advertisement-text">Advertisement</div>
          <div id="at_article_rightcol_desktop"/>
        </div>}
    </div>;
  }
}

export default ArticlePopularPosts;
