import React from 'react';
import AccordionItem from '../accordion';
import '../../css/components/_accordian.scss';

class Faq extends React.Component {
  render () {
    if (this.props.isExpandedView) {
      return <div className={'my-2'}>
        <h2 className={'mb-2 font-normal text-3xl leading-snug'}>{this.props.title}</h2>
        {this.props.data.map((data, key) => {
          return (
            <div key={`faq-${key}`}>
              <h3>
                {data.title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: data.text}}/>
            </div>
          );
        })}
      </div>;
    }

    return <div>
      <h2 className={'mb-2 font-semibold leading-snug'}
          style={{marginBottom: '10px'}}>{this.props.title}</h2>
      <ul {...{className: 'accordion-list'}}>
        {this.props.data.map((data, key) => {
          return (
            <li {...{className: 'accordion-list__item', key}}>
              <AccordionItem {...data}/>
            </li>
          );
        })}
      </ul>
    </div>;
  }

}


export default Faq;
